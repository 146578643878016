export class CustomersTable {
  public static customers: any = [
    {
      msisdn: '123456',
      status: 'OK',
      amount: '1.23',
      vendorRef: '123456-78901-2345678',
      id: '123456-78901-2345678',
      vendorCode: '0',
      vendorMessage: 'OK',
      createdAt: '10/14/1950 01:00:00',
      _userId: 1,
      _createdDate: '09/07/2016',
      _updatedDate: '05/31/2013'
    }
  ];
}
