<kt-portlet>
	<kt-portlet-header [sticky]="true" [title]="'Bill payments list'" [class]="'card-head-lg'"
		[viewLoading$]="dataSource.loading$">
<!--		<ng-container ktPortletTools>-->
<!--			<button (click)="createProduct()" mat-raised-button color="primary" matTooltip="Create new product">New-->
<!--				product</button>-->
<!--		</ng-container>-->
	</kt-portlet-header>
	<!-- end::Header -->

	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="form mb-3">
			<!-- start::FILTERS -->
<!--			<div class="form-filtration">-->
<!--				<div class="row align-items-center">-->
<!--					<div class="col-md-2 kt-margin-bottom-10-mobile">-->
<!--&lt;!&ndash;						<mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;							<mat-select [(value)]="filterStatus" (selectionChange)="loadProductsList()"&ndash;&gt;-->
<!--&lt;!&ndash;								class="mat-form-field mat-form-field-fluid">&ndash;&gt;-->
<!--&lt;!&ndash;								<mat-option value="">All</mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;								<mat-option value="0">Selling</mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;								<mat-option value="1">Sold</mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;							</mat-select>&ndash;&gt;-->
<!--&lt;!&ndash;							<mat-hint align="start">&ndash;&gt;-->
<!--&lt;!&ndash;								<strong>Filter by Status</strong>&ndash;&gt;-->
<!--&lt;!&ndash;							</mat-hint>&ndash;&gt;-->
<!--&lt;!&ndash;						</mat-form-field>&ndash;&gt;-->
<!--					</div>-->
<!--					<div class="col-md-2 kt-margin-bottom-10-mobile">-->
<!--&lt;!&ndash;						<mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;							<mat-select [(value)]="filterCondition" (selectionChange)="loadProductsList()"&ndash;&gt;-->
<!--&lt;!&ndash;								class="mat-form-field mat-form-field-fluid">&ndash;&gt;-->
<!--&lt;!&ndash;								<mat-option value="">All</mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;								<mat-option value="0">New</mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;								<mat-option value="1">Used</mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;							</mat-select>&ndash;&gt;-->
<!--&lt;!&ndash;							<mat-hint align="start">&ndash;&gt;-->
<!--&lt;!&ndash;								<strong>Filter by Contidion</strong>&ndash;&gt;-->
<!--&lt;!&ndash;							</mat-hint>&ndash;&gt;-->
<!--&lt;!&ndash;						</mat-form-field>&ndash;&gt;-->
<!--					</div>-->
<!--					<div class="col-md-4 kt-margin-bottom-10-mobile">-->
<!--&lt;!&ndash;						<mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;							<input matInput placeholder="Search product" #searchInput placeholder="Search"&ndash;&gt;-->
<!--&lt;!&ndash;								class="mat-form-field mat-form-field-fluid">&ndash;&gt;-->
<!--&lt;!&ndash;							<mat-hint align="start">&ndash;&gt;-->
<!--&lt;!&ndash;								<strong>Search in all fields</strong>&ndash;&gt;-->
<!--&lt;!&ndash;							</mat-hint>&ndash;&gt;-->
<!--&lt;!&ndash;						</mat-form-field>&ndash;&gt;-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->
			<!-- end::FILTERS -->

			<!-- start::GROUP ACTIONS -->
			<!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
			<!-- Group actions are shared for all LISTS | See '../../_shared' folder -->
			<div class="row align-items-center collapse form-group-actions kt-margin-top-20 kt-margin-bottom-20"
				[ngClass]="{'show' : selection.selected.length > 0}">
				<div class="col-xl-12">
					<div class="form-group form-group-inline">
						<div class="form-label form-label-no-wrap">
							<label class="font-bold font-danger-">Selected records count:
								{{ selection.selected.length }}</label>
						</div>
						<div>
							<button mat-raised-button color="warn" (click)="deleteProducts()"
								matTooltip="Delete selected products" class="mat-button-mt-4">
								<mat-icon>delete</mat-icon>
								Delete All
							</button>&nbsp;
							<!-- Call 'delete-entity-dialog' from _shared folder -->
							<button mat-raised-button (click)="fetchProducts()" matTooltip="Fetch selected products"
								class="mat-button-mt-4">
								<mat-icon>clear_all</mat-icon>
								Fetch Selected Records
							</button>&nbsp;
							<!-- Call 'fetch-entity-dialog' from _shared folder -->
							<button mat-raised-button (click)="updateStatusForProducts()"
								matTooltip="Update status for selected products" class="mat-button-mt-4">
								<mat-icon>update</mat-icon>
								Update status
							</button><!-- Call 'update-stated-dialog' from _shared folder -->
						</div>
					</div>
				</div>
			</div>
			<!-- end::GROUP ACTIONS -->
		</div>
		<!-- end::FILTERS & GROUP ACTIONS -->

		<!-- MATERIAL TABLE | Binded to datasources -->
		<!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
		<div class="mat-table-wrapper">
			<!-- Checkbox Column -->
			<!-- Table with selection -->
			<!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
			<mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
				matSortActive="createdAt" matSortDirection="desc" matSortDisableClear>
				<!-- Checkbox Column -->
				<ng-container matColumnDef="createdAt">
					<mat-header-cell *matHeaderCellDef mat-sort-header class="mat-column-vinCode">Date
					</mat-header-cell>
					<mat-cell *matCellDef="let product" class="mat-column-vinCode">{{product.createdAt}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="msisdn">
					<mat-header-cell *matHeaderCellDef mat-sort-header>MSISDN</mat-header-cell>
					<mat-cell *matCellDef="let product">{{product.msisdn}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="billingAccountNumber">
					<mat-header-cell *matHeaderCellDef mat-sort-header>BAN</mat-header-cell>
					<mat-cell *matCellDef="let product">{{product.billingAccountNumber}}</mat-cell>
				</ng-container>



				<ng-container matColumnDef="ourRef">
					<mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
					<mat-cell *matCellDef="let product">{{product.id}}
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="amount">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Amount</mat-header-cell>
					<mat-cell *matCellDef="let product">{{product.amount.currency}} {{product.amount.value}}</mat-cell>
				</ng-container>

        <ng-container matColumnDef="vendorRef">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Ref</mat-header-cell>
          <mat-cell *matCellDef="let product">{{product.vendorRef}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
					<mat-cell *matCellDef="let product">
						<span
							class="label label-lg label-light-{{ getItemCssClassByStatus(product.status) }} label-inline">{{product.status}}</span>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="vendorResponseCode">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Code</mat-header-cell>
          <mat-cell *matCellDef="let product">{{product.vendorResponseCode}}</mat-cell>
				</ng-container>

        <ng-container matColumnDef="vendor">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Vendor</mat-header-cell>
          <mat-cell *matCellDef="let product">{{product.vendor}}</mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<div class="mat-table-message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<!-- start: BOTTOM -->
		<div class="mat-table-bottom">
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<mat-paginator [pageSize]="10" [pageSizeOptions]="[3, 5, 10]" [length]="dataSource.paginatorTotal$ | async"
				[showFirstLastButtons]="true"></mat-paginator>
		</div>
		<!-- end: BOTTOM -->
	</kt-portlet-body>
	<!-- end::Body -->
</kt-portlet>
