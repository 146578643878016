export class BaseModel {
  // Edit
  // tslint:disable-next-line:variable-name
  _isEditMode = false;
  // Log
  // tslint:disable-next-line:variable-name
  _userId = 0; // Admin
  // tslint:disable-next-line:variable-name
  _createdDate: string;
  // tslint:disable-next-line:variable-name
  _updatedDate: string;

}
