<!--begin::Form-->
<form [formGroup]="socialNetworksForm" class="form form-group-seperator-dashed">

	<kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
		Oh snap! Change a few things up and try submitting again.
	</kt-alert>
	<div class="form-section form-section-first">
		<div class="form-group row">
			<div class="col-lg-4 kt-margin-bottom-20-mobile">
				<mat-form-field class="mat-form-field-fluid">
					<input matInput placeholder="Enter LinkedIn address" formControlName="linkedIn" />
					<mat-hint align="start">Please enter
						<strong>Linked In</strong> address
					</mat-hint>
				</mat-form-field>
			</div>
			<div class="col-lg-4 kt-margin-bottom-20-mobile">
				<mat-form-field class="mat-form-field-fluid">
					<input matInput placeholder="Enter Facebook address" formControlName="facebook" />
					<mat-hint align="start">Please enter
						<strong>Facebook</strong> address
					</mat-hint>
				</mat-form-field>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-lg-4 kt-margin-bottom-20-mobile">
				<mat-form-field class="mat-form-field-fluid">
					<input matInput placeholder="Enter Twitter address" formControlName="twitter" />
					<mat-hint align="start">Please enter
						<strong>Twitter</strong> address
					</mat-hint>
				</mat-form-field>
			</div>
			<div class="col-lg-4 kt-margin-bottom-20-mobile">
				<mat-form-field class="mat-form-field-fluid">
					<input matInput placeholder="Enter Instagram address" formControlName="instagram" />
					<mat-hint align="start">Please enter
						<strong>Instagram</strong> address
					</mat-hint>
				</mat-form-field>
			</div>
		</div>
	</div>
</form>
<!--end::Form-->
