<div class="card card-custom" [ngClass]="{ 'card-body-progress' : viewLoading, 'card-body-progress-overlay' : loadingAfterSubmit }">
	<div class="card-header">
		<div class="card-title">
			<h3 class="card-label">
				{{ data.isNew ? 'Create' : 'Update'}} specification
			</h3>
		</div>
	</div>
	<!--begin::Form-->
	<form class="form" [formGroup]="specificationEditForm" autocomplete="off">
		<div class="card-body">
			<div class="card-body-progress">
				<mat-spinner [diameter]="20"></mat-spinner>
			</div>

			<div class="form-section form-section-first">
				<div class="form-group">
					<mat-form-field class="mat-form-field-fluid">
						<mat-select placeholder="Enter Specification Type" formControlName="name">
							<mat-option value="{{sp}}" *ngFor="let sp of specificationsDictionary;">{{sp}}</mat-option>
						</mat-select>
						<mat-error *ngIf="isControlHasError('name','required')">
							<strong>Specification Type is required</strong>
						</mat-error>
					</mat-form-field>
				</div>
				<div class="form-group">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput placeholder="Enter Value" formControlName="text" />
						<mat-error *ngIf="isControlHasError('text','required')">
							<strong>Value is required</strong>
						</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="card-footer">
			<div class="d-flex justify-content-between">
				<div class="mr-2"></div>
				<div>
					<button type="button" mat-raised-button cdkFocusInitial (click)="onNoClick()" matTooltip="Cancel changes">Cancel</button>&nbsp;
					<button type="submit" mat-raised-button color="primary" (click)="save()" [disabled]="viewLoading" matTooltip="Save changes">Save</button>
				</div>
			</div>
		</div>
	</form>
	<!--end::Form-->
</div>
