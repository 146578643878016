<kt-portlet>
	<!-- See prop => '../../_core/models/data-sources/_base.datasource.ts' (loading$) -->
	<kt-portlet-header [class]="'card-head-lg'" [viewLoading$]="dataSource.loading$">
		<!-- PORTLET LOADING | Binded to TABLE Datasource -->

		<ng-container ktPortletTitle>
			<span translate="ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST">Top-up list</span>
			<!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
			<!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
		</ng-container>

	</kt-portlet-header>
	<!-- end::Header -->

	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="form mb-3">
			<!-- start::FILTERS -->
<!--			<div class="form-filtration">-->
<!--				<div class="row align-items-center">-->
<!--					<div class="col-md-2">-->
<!--						<mat-form-field>-->
<!--							<mat-select [(value)]="filterStatus" (selectionChange)="loadCustomersList()">-->
<!--								<mat-option value="">All</mat-option>-->
<!--								<mat-option value="OK">OK</mat-option>-->
<!--								<mat-option value="ERROR">ERROR</mat-option>-->
<!--							</mat-select>-->
<!--							<mat-hint align="start">-->
<!--								<strong>Filter</strong> by Status-->
<!--							</mat-hint>-->
<!--						</mat-form-field>-->
<!--					</div>-->


<!--&lt;!&ndash;					<div class="col-md-2 kt-margin-bottom-10-mobile">&ndash;&gt;-->
<!--&lt;!&ndash;						<mat-form-field class="mat-form-field-fluid">&ndash;&gt;-->
<!--&lt;!&ndash;							<input matInput placeholder="Search customer" #searchInput />&ndash;&gt;-->
<!--&lt;!&ndash;							<mat-hint align="start">&ndash;&gt;-->
<!--&lt;!&ndash;								<strong>Search</strong> in all fields&ndash;&gt;-->
<!--&lt;!&ndash;							</mat-hint>&ndash;&gt;-->
<!--&lt;!&ndash;						</mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;					</div>&ndash;&gt;-->

<!--				</div>-->
<!--			</div>-->
			<!-- end::FILTERS -->

			<!-- start::GROUP ACTIONS -->
			<!-- Group actions list: 'Delete selected' | 'Fetch selected' | 'Update status for selected' -->
			<!-- Group actions are shared for all LISTS | See '../../_shared' folder -->
			<div class="row align-items-center collapse form-group-actions kt-margin-top-20 kt-margin-bottom-20"
				[ngClass]="{'show' : selection.selected.length > 0}">
				<!-- We show 'Group Actions' div if smth are selected -->
				<div class="col-xl-12">
					<div class="form-group form-group-inline">
						<div class="form-label form-label-no-wrap">
							<label class="font-bold font-danger">
								<span translate="ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT"></span>
								{{ selection.selected.length }}
							</label>
							<!-- selectedCountsTitle => function from codeBehind (customer-list.component.ts file) -->
							<!-- selectedCountsTitle => just returns title of selected items count -->
							<!-- for example: Selected records count: 4 -->
						</div>
						<div>

							<!-- Call 'fetch-entity-dialog' from _shared folder -->
						</div>
					</div>
				</div>
			</div>
			<!-- end::GROUP ACTIONS -->
		</div>
		<!-- end::FILTERS & GROUP ACTIONS -->

		<!-- MATERIAL TABLE | Binded to datasources -->
		<!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
		<div class="mat-table-wrapper">
			<mat-table class="lmat-elevation-z8" #table [dataSource]="dataSource" matSort #sort1="matSort"
				matSortActive="createdAt" matSortDirection="desc" matSortDisableClear>
				<!-- Checkbox Column -->

				<!-- Table with selection -->
				<!-- https://run.stackblitz.com/api/angular/v1?file=app%2Ftable-selection-example.ts -->
<!--				<ng-container matColumnDef="select">-->
<!--					<mat-header-cell *matHeaderCellDef class="mat-column-checkbox">-->
<!--					</mat-header-cell>-->
<!--					<mat-cell *matCellDef="let row" class="mat-column-checkbox">-->
<!--					</mat-cell>-->
<!--				</ng-container>-->

				<ng-container matColumnDef="createdAt">
					<!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
					<mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
					<mat-cell *matCellDef="let customer">{{customer.createdAt}}</mat-cell>
				</ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
          <mat-cell *matCellDef="let customer">
						<span
              class="label label-lg label-light-{{ getItemCssClassByStatus(customer.status) }} label-inline">{{  getItemStatusString(customer.status) }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Amount</mat-header-cell>
          <mat-cell *matCellDef="let customer">{{customer.amount.currency}} {{customer.amount.value}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Our Ref</mat-header-cell>
					<mat-cell *matCellDef="let customer">{{customer.id}}</mat-cell>
				</ng-container>


        <ng-container matColumnDef="vendorRef">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Ref</mat-header-cell>
					<mat-cell *matCellDef="let customer">{{customer.vendorRef}}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="vendorMessage">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Message</mat-header-cell>
					<mat-cell *matCellDef="let customer">
						<a href="ecommerce/customers#" class="font-weight-bold">{{customer.vendorMessage}}</a>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="vendorCode">
					<mat-header-cell *matHeaderCellDef mat-sort-header>Vendor Code</mat-header-cell>
					<mat-cell *matCellDef="let customer">{{customer.vendorCode}}</mat-cell>
				</ng-container>


				<ng-container matColumnDef="msisdn">
					<mat-header-cell *matHeaderCellDef mat-sort-header>MSISDN</mat-header-cell>
          <mat-cell *matCellDef="let customer">{{customer.msisdn}}</mat-cell>
				</ng-container>

        <ng-container matColumnDef="vendor">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Vendor</mat-header-cell>
          <mat-cell *matCellDef="let customer">{{customer.vendor}}</mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
			</mat-table>
			<!-- Message for empty data  -->
			<div class="mat-table-message" *ngIf="!dataSource.hasItems">No records found</div>
			<div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">Please wait....</div>
		</div>

		<!-- start: BOTTOM -->
		<div class="mat-table-bottom">
			<!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
			<mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
			<!-- MATERIAL PAGINATOR | Binded to dasources -->
			<!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
			<mat-paginator [pageSize]="10" [pageSizeOptions]="[3, 5, 10]" [length]="dataSource.paginatorTotal$ | async"
				[showFirstLastButtons]="true"></mat-paginator>
		</div>
		<!-- end: BOTTOM -->
	</kt-portlet-body>
	<!-- end::Body -->

</kt-portlet>
