<!--begin::Form-->
	<form *ngIf="user" [formGroup]="changePasswordForm" class="form form-group-seperator-dashed">

		<kt-alert *ngIf="hasFormErrors" type="warn" [showCloseButton]="true" [duration]="10000" (close)="onAlertClose($event)">
			Oh snap! Password not match.
		</kt-alert>
		<div class="form-section form-section-first">
			<div class="form-group form-group row mb-0">
				<div class="col-lg-8 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput type="password" id="password" autocomplete="off" placeholder="Enter Password" formControlName="password" />
						<mat-error>Password is
							<strong>required</strong>
						</mat-error>
						<mat-hint align="start">Please enter
							<strong>Password</strong>
						</mat-hint>
					</mat-form-field>
				</div>
			</div>
			<div class="form-group row">
				<div class="col-lg-8 kt-margin-bottom-20-mobile">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput type="password" id="confirmPassword" autocomplete="off" placeholder="Confirm password" formControlName="confirmPassword" />
						<mat-error>Confirm password is
							<strong>required</strong>
						</mat-error>
						<mat-hint align="start">Please conirm
							<strong>Password</strong>
						</mat-hint>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="separator separator-dashed my-6"></div>
		<div class="form-actions form-actions-solid">
			<button type="button" [disabled]="changePasswordForm.invalid" mat-raised-button color="primary" (click)="onSubmit()" [disabled]="viewLoading" matTooltip="Save changes">
				Change password
			</button>
		</div>
	</form>
<!--end::Form-->
