<!-- begin:: Header Topbar -->
<ng-container>
<!--  <ng-container *ngIf="searchDisplay">-->
<!--    &lt;!&ndash;begin: Search &ndash;&gt;-->
<!--    <kt-search-dropdown  [icon]="'./assets/media/svg/icons/General/Search.svg'" [useSVG]="true"></kt-search-dropdown>-->
<!--    &lt;!&ndash;end: Search &ndash;&gt;-->
<!--  </ng-container>-->





  <ng-container *ngIf="userDisplay">
    <ng-container *ngIf="userLayout === 'dropdown'">
      <kt-user-profile [userDropdownStyle]="userDropdownStyle"></kt-user-profile>
    </ng-container>
    <ng-container *ngIf="userLayout === 'offcanvas'">
      <!--begin: User bar -->
      <kt-user-profile4></kt-user-profile4>
      <!--end: User bar -->
    </ng-container>
  </ng-container>
</ng-container>
<!-- end:: Header Topbar -->
