export class QueryResultsModel {
  // fields
  content: any[];
  totalElements: number;
  errorMessage: string;

  constructor(items: any[] = [], totalElements: number = 0, errorMessage: string = '') {
    this.content = items;
    this.totalElements = totalElements;
  }
}


