export class MenuConfig {
  public defaults: any = {
    header: {
      self: {},
      items: [
        {
          title: 'Data',
          root: true,
          alignment: 'left',
          toggle: 'click',
          submenu: [
                {
                  title: 'Top-ups',
                  page: '/ecommerce/topups'
                },
                {
                  title: 'Bill payments',
                  page: '/ecommerce/billpayments'
                }
          ]
        },
      ]
    },
    aside: {
      self: {},
      items: [
        // {
        //   title: 'Dashboard',
        //   root: true,
        //   icon: 'flaticon2-architecture-and-city',
        //   page: '/dashboard',
        //   translate: 'MENU.DASHBOARD',
        //   bullet: 'dot',
        // },
        {
          title: 'Data',
          bullet: 'dot',
          icon: 'flaticon2-list-2',
          root: true,
          permission: 'accessToECommerceModule',
          submenu: [
            {
              title: 'Top-ups',
              page: '/ecommerce/topups'
            },
            {
              title: 'Bill Payments',
              page: '/ecommerce/billpayments'
            },
          ]
        }
      ]
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
