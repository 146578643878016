import { BaseModel } from '../../_base/crud';

export class ContentModel<T> {
  content: T;
}

export class CustomerModel  extends BaseModel {

  createdAt: Date;
  amount: string;
  status: string;
  vendorRef: string;
  id: string;
  vendorCode: string;
  vendorMessage: string;
  msisdn: string;
  vendor: string;



  clear() {
    this.createdAt = new Date();
    this.amount = '';
    this.status = '';
    this.vendorRef = '';
    this.id = '';
    this.vendorCode = '';
    this.vendorMessage = '';
    this.msisdn = "";
    this.vendor = "";
  }
}
