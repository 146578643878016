import { BaseModel } from '../../_base/crud';
import { ProductSpecificationModel } from './product-specification.model';
import { ProductRemarkModel } from './product-remark.model';

export class ContentModel<T> {
  content: T;
}


export class ProductModel extends BaseModel {
  createdAt: Date;
  amount: string;
  status: string;
  billingAccountNumber: string;
  id: string;
  vendorCode: string;
  vendorResponseCode: string;
  msisdn: string;
  vendor: string;


  clear() {
    this.billingAccountNumber = '';
    this.createdAt = new Date();
    this.status = "";
    this.id = "";
    this.vendorCode = '';
    this.vendorResponseCode = '';
    this.msisdn = "";
    this.vendor = "";
  }
}
